import React, { FunctionComponent } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper'
import { Skeleton } from '@components/ui'

const CategoryRoundWidgetSkeleton: FunctionComponent = () => {
  const css = { maxWidth: '100%', height: 'auto' }
  return (
    <div className="flex flex-row justify-between px-0 py-3 pt-6 pr-0 sm:px-0 sm:pr-0 sm:py-8 home-banner-swiper m-hide-navigation overflow-hidden">
      
        {Array.from(({ length: 6 }), (_, index) => (
            <div className="flex flex-col px-1 group" key={index}>
              <div className="flex flex-col justify-items-center image-container home-category">
                <div className="rounded-full bg-slate-200 h-[84px] w-[84px]" />
              </div>
              <div className="flex flex-col justify-items-center mt-2">
                <div className="h-2 bg-slate-200 rounded w-[80px]" />
              </div>
            </div>
        ))}
      
    </div>
  )
}

export default CategoryRoundWidgetSkeleton
